<template>
  <div>
    <div class="mb-8 flex" style="align-items: center; justify-content: space-between">
      <h2>Actualización masiva de precios</h2>
      <vs-button @click="$router.push({name: 'deliveries'})">
        Regresar
      </vs-button>
    </div>
    <div class="mb-8">
      <import-excel :onSuccess="loadDataInTable"/>
    </div>
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <vx-card>
      <vs-table stripe pagination :max-items="50" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              {{ tr.id }}
            </vs-td>
            <vs-td>
              {{ tr.department }}
            </vs-td>
            <vs-td>
              {{ tr.province }}
            </vs-td>
            <vs-td>
              {{ tr.district }}
            </vs-td>
            <vs-td>
              {{ tr.price }}
            </vs-td>
            <vs-td>
              {{ tr.deliveryPrice }}
            </vs-td>
            <vs-td>
              {{ tr.state }}
            </vs-td>
            <vs-td>
              {{ tr.status }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-button @click="importData" class="mr-6 mt-5">Actualizar</vs-button>
    </vx-card>
  </div>
</template>

<script>
import ImportExcel from '@/components/excel/ImportExcel.vue'

import { db } from '@/firebase/firebaseConfig'

export default {
  name: 'ImportPassengers',
  components: {
    ImportExcel
  },
  data() {
    return {
      tableData: [],
      header: [],
      sheetName: '',
      initProgress: false,
      categories: [],
      colors: [],
      hasErrors: false
    }
  },
  methods: {
    loadDataInTable({results, header, meta}) {
      this.header = header
      // Add state
      this.header.push('Estado')
      this.tableData = results
      this.tableData.forEach((d) => {
        this.$set(d, 'status', 'En espera')
        if (!d.id) {
          this.$set(d, 'status', 'Falta ID')
          this.$set(d, 'ID', 'INCOMPLETO')
        }
        if (!d.department) {
          this.$set(d, 'status', 'Falta departamento')
          this.$set(d, 'department', 'INCOMPLETO')
        }
        if (!d.province) {
          this.$set(d, 'status', 'Falta provincia')
          this.$set(d, 'province', 'INCOMPLETO')
        }
        if (!d.district) {
          this.$set(d, 'status', 'Falta distrito')
          this.$set(d, 'district', 'INCOMPLETO')
        }
        if (!d.price) {
          this.$set(d, 'status', 'Falta precio')
          this.$set(d, 'price', 'INCOMPLETO')
        }
      })
      this.sheetName = meta.sheetName
    },
    async importData() {
      try {
        this.initProgress = true
        for await (let d of this.tableData) {
          if (d.status === 'En espera') {
            await this.updateDeliveryArea(d)
          }
        }
        if (!this.hasErrors) {
          this.$vs.notify({
            color: 'success',
            title: 'Carga de información',
            text: 'Actualización realizada correctamente.'
          })
        } else {
          this.$vs.notify({
            color: 'warning',
            title: 'Carga de información',
            text: 'Actualización completada con errores.'
          })
        }

        this.initProgress = false
      } catch (e) {
        this.initProgress = false
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Save data in firebase
     * @returns {Promise<void>}
     */
    async updateDeliveryArea(deliveryArea) {
      try {
        await db.collection('deliveries').doc(deliveryArea.id).update({
          price: parseFloat(deliveryArea.price),
          deliveryPrice: parseFloat(deliveryArea.deliveryPrice),
          state: deliveryArea.state === 1
        })
        deliveryArea.status = 'Ok'
      } catch (e) {
        console.log(e)
        this.hasErrors = true
        deliveryArea.status = 'Error'
      }
    }
  }
}
</script>
